<template>
  <b-img
    fluid
    :src="logoImgUrl"
    v-bind="logoProps"
    alt="Center image"
  />
</template>

<script>
/* eslint-disable global-require */

import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/logo.png'),
      logoProps: { width: 220 },
    }
  },
  computed: {
    logoImgUrl() {
      return this.logoImg
    },
  },
}
</script>
